<template>
    <div>
        <template v-for="item_number in row.item.number">
            <div>
                <a v-if="item_number.url"
                   @click.prevent="$root.openDocument(item_number.name)"
                   :href="$root.getDocumentUrl(item_number.name)"
                >{{ item_number.name }}</a>
                <div v-else>
                    {{ item_number.name }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>